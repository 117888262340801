import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default function BlogPostAuthor({
  author,
  href,
  date,
  formattedDate,
  timeToRead,
}) {
  return (
    <div className="flex items-center mt-6">
      <div className="flex-shrink-0">
        <Link to={href} title={author}>
          <span className="sr-only">{author}</span>
          <StaticImage
            className="w-10 h-10 rounded-full"
            layout="fixed"
            src="../images/logo.png"
            width={40}
            height={40}
            quality={95}
            alt={author + "'s logo"}
          />
        </Link>
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">
          <Link to={href} title={author} className="hover:underline">
            {author}
          </Link>
        </p>
        <div className="flex space-x-1 text-sm text-gray-500">
          <time dateTime={date}>{formattedDate}</time>
          <span aria-hidden="true">&middot;</span>
          <span>{timeToRead} minute read</span>
        </div>
      </div>
    </div>
  );
}
