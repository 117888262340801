import * as React from 'react';
import PropTypes from 'prop-types';

import Dots from './dots';
import Header from './header';
import Footer from './footer';
import Seo from './seo';

const Layout = ({
  title,
  description,
  date,
  lastUpdated,
  tags,
  image,
  canonicalURL,
  absoluteURL,
  children,
}) => {
  return (
    <div className="bg-gray-50">
      <Seo
        title={title}
        description={description}
        date={date}
        lastUpdated={lastUpdated}
        tags={tags}
        image={image}
        canonicalURL={canonicalURL}
        absoluteURL={absoluteURL}
      />
      <div className="relative overflow-hidden">
        <Dots />
        <div className="relative pt-6 pb-16 sm:pb-24">
          <Header />
          {children}
        </div>
        <div className="relative">
          <div
            className="absolute inset-0 flex flex-col"
            aria-hidden="true"
          >
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-800" />
          </div>
          {/* <div className="px-4 mx-auto max-w-7xl sm:px-6"><SiteContent /></div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string,
  lastUpdated: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  canonicalURL: PropTypes.string,
  absoluteURL: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
